// Grabs GEO Headers from CDN and populates them in the JS context

function setGeoVars(geoData) {
    // Enable using ?geoCountryCode=XX in URL for production validation
    var loc_override = window.location.toString()
    var geoCountryCodeOverride = loc_override.replace(/.*[?|&]geoCountryCode=([\w-]*).*|^.*$/, '$1')
    var geoStateNameOverride = loc_override.replace(/.*[?|&]geoStateName=([\w-]*).*|^.*$/, '$1')
    var geoStateCodeOverride = loc_override.replace(/.*[?|&]geoStateCode=([\w-]*).*|^.*$/, '$1')

    // Set window vars for geo Data
    window.geoCityCode = geoData.geoCityCode || null
    window.geoCountryCode = geoCountryCodeOverride || geoData.geoCountryCode || null
    window.geoStateName = geoStateNameOverride || geoData.geoStateName || null
    window.geoStateCode = geoStateCodeOverride || geoData.geoStateCode || null
    window.geoStateCode = window.geoStateCode ? window.geoStateCode.toUpperCase() : null

    // Dispatch event to let other scripts know that geo vars are ready
    if (window.geoCountryCode || window.geoStateCode) {
        window.dispatchEvent(new Event('geoVarsReady'))
    }
}

async function getGeoData() {
    // If geoVars is already defined from a Cloudflare worker, use that
    if (typeof window.geoVars !== 'undefined') {
        setGeoVars(window.geoVars)
        return
    }

    // Otherwise Fetch Geo Headers from CDN
    fetch(
        '/money/geo/api/',
    ).then(
        (response) => response.json(),
    ).then((geoData) => {
        setGeoVars(geoData)
    })
}

getGeoData()
